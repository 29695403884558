/**
 * @author gudiskis PrivaSphere confidential, (c) 2024 - 2024 all rights reserved
 */
import extHlp from '../../angular/src/assets/extHlp.json';
export class PageData {
    constructor() {
        const data = extHlp;
        this.pages = data.page;
    }
    findPageByC(cValue) {
        const page = this.pages.find(page => page.c === cValue);
        if (page) {
            return { n: page.n, t: page.t };
        }
        return undefined;
    }
}
