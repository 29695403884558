/**
 * @author gudiskis PrivaSphere confidential, (c) 2023 - 2024 all rights reserved
 */
import { deTranslation } from './LangueFormat.de';
import { enTranslation } from './LangueFormat.en';
import { frTranslation } from './LangueFormat.fr';
import { itTranslation } from './LangueFormat.it';
export const translations = {
    en: enTranslation,
    de: deTranslation,
    fr: frTranslation,
    it: itTranslation
};
export function getTypo3LanguageNumber() {
    switch (currentLanguageTS) {
        case 'en':
            return 0;
        case 'de':
            return 1;
        case 'fr':
            return 3;
        case 'it':
            return 4;
        default:
            return 0;
    }
}
