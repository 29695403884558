/**
 * @author gudiskis PrivaSphere confidential, (c) 2024 - 2024 all rights reserved
 *
 * Used for single functions that are used in forms but have no connections between each other
 *
 */
import { LoggerFactory } from "myd-commons";
import constVar from "../../angular/src/assets/const.json";
import { Utils } from "./Utils";
const logger = LoggerFactory.getLogger("FormsUtils");
export function copyDropDownTextToField(dropDownElement, inputID) {
    var _a;
    const inputElement = document.getElementById(inputID);
    if (!inputElement) {
        logger.error(`Input element with id ${inputID} not found`);
        return;
    }
    const selectedText = ((_a = dropDownElement.selectedOptions[0]) === null || _a === void 0 ? void 0 : _a.text) || '';
    for (let i = 0; i < dropDownElement.options.length; i++) {
        const optionText = dropDownElement.options[i].text;
        if (inputElement.value.includes(optionText)) {
            //lorenz.zimmermann@bav.admin.ch  240530_1031 Anpassung Dateneinlieferung BAV (zr419.21)
            inputElement.value = inputElement.value.replace(optionText, "").trim();
            logger.info(`Input "${inputID}" value changed to "${inputElement.value}"`);
        }
    }
    inputElement.value = inputElement.value.trim();
    inputElement.value += " " + selectedText;
    inputElement.value = inputElement.value.trim();
}
//lorenz.zimmermann@bav.admin.ch  240530_1031 Anpassung Dateneinlieferung BAV (zr419.21)
export function setDropdownOnPageLoad(parameter, dropdownID, inputID) {
    const preSelValue = getURLHashValue(parameter);
    if (preSelValue) {
        const dropdownElement = document.getElementById(dropdownID);
        if (!dropdownElement)
            logger.warn(`"${dropdownID}" dropdown has not been found`);
        const option = Array.from(dropdownElement.options).find(option => option.title === preSelValue);
        if (option) {
            option.selected = true;
            if (inputID)
                copyDropDownTextToField(dropdownElement, inputID);
            logger.info(`Dropdown "${dropdownID}" set to "${option.text}"`);
            return;
        }
    }
    logger.warn(`"${parameter}" has not been found as a parameter in URL`);
}
function getURLHashValue(parameter) {
    try {
        const url = new URL(window.location.href);
        const hash = url.hash;
        if (!hash && "" !== hash && 1 >= hash.length)
            return null;
        const hashParams = new URLSearchParams(hash.substr(1));
        let parameterVal = hashParams.get(parameter);
        if (!parameterVal)
            return null;
        //there can be multiple fragments in the URL
        //https://stackoverflow.com/questions/4968470/using-multiple-fragment-identifiers-in-a-url
        //but in the oficcial documentation this is not recomended https://www.rfc-editor.org/rfc/rfc3986#section-3.4
        let parameterWithHasherArr = parameterVal.split('#');
        parameterVal = parameterWithHasherArr[0];
        updateLanguageButtons(parameter, parameterVal);
        return parameterVal;
    }
    catch (e) {
        logger.warn(`Error thrown while parsing #${parameter} URL: "${e}"`);
    }
    return null;
}
function updateLanguageButtons(parameter, parameterVal) {
    const lanIDArr = ["de", "it", "fr", "en"];
    for (let ID of lanIDArr) {
        let li = document.getElementById(ID);
        if (!li) {
            logger.warn(`${ID} is not found`);
            continue;
        }
        let a = li.querySelector('a');
        if (a) {
            a.href += `#${parameter}=${parameterVal}`;
            logger.info(`Updated ${ID} language button with ${parameter}=${parameterVal}`);
        }
    }
}
export function checkHiddenInputsFormsSubmit() {
    let contaFrmSnd = Utils.getContaFrmSnd();
    if (contaFrmSnd) {
        contaFrmSnd.addEventListener("click", (event) => {
            checkHiddenInputsForms(event);
        });
    }
}
function checkHiddenInputsForms(event) {
    const form = document.querySelectorAll('[id*="' + constVar['sd_contaFormIdForJs'] + '"]');
    if (!form) {
        logger.warn('Didnt find any forms on the page');
        return;
    }
    form.forEach((formItem) => {
        checkHiddenRequiredInputs(formItem, event);
    });
}
function isElementOrParentHidden(element, maxLevels = 7) {
    let level = 0;
    while (element && level < maxLevels) {
        const style = window.getComputedStyle(element);
        if (style.display === 'none') {
            return true;
        }
        element = element.parentElement;
        level++;
    }
    return false;
}
function checkHiddenRequiredInputs(form, event) {
    const requiredInputs = form.querySelectorAll('input[required], select[required], textarea[required]');
    requiredInputs.forEach((input) => {
        let hidden = isElementOrParentHidden(input);
        if (input.type === 'file') {
            const browseBtn = document.getElementById(input.id + 'BrowseBtn');
            if (browseBtn) {
                hidden = isElementOrParentHidden(browseBtn);
            }
            else {
                hidden = true;
            }
        }
        // Log the warning if the input is hidden and doesn't match specific IDs
        if (hidden && constVar["gc_F_SUBJ"] !== input.id && constVar["sd_fFrom"] !== input.id) {
            let title = input.title || 'no title';
            logger.warn(`Input with title='${title}' id='${input.id}' is required but is hidden.
            	Most likely what happened was you made an input required on your sqi but you also hid it,
            	that’s why when you try to submit it you get this error. Please fix the hidden input logic for the form to work.
            	The form cannot be submitted.`);
        }
    });
}
