/**
 * @author gudiskis PrivaSphere confidential, (c) 2023 - 2024 all rights reserved
 */
import constVar from "../../../angular/src/assets/i18n/en.json";
export var enTranslation = {
    ts_Delete: "Delete",
    ts_CheckAttaBeforeDispatch: "Attachment-check prior to dispatch",
    ts_TooBig: "Too big",
    ts_attachment: "attachment",
    ts_available: "available",
    ts_cumulated: "cumulated",
    ts_fileNameExtension: "filename-extension",
    ts_instead: "instead",
    ts_moreInfo: "Show more information",
    ts_in_aNewWindow: "in a new Window",
    ts_MoreThanOneNumberGroupPresent: "More than one number group is present.",
    ts_NoNumberPresent: "No number is present.",
    ts_TooManyDecimalDelimiters: "Too many decimal delimiters.",
    ts_TooManyMinusSigns: "Too many minus signs.",
    ts_LeadingZerosRemoved: "Leading zeros have been removed.",
    ts_WhitespaceRemoved: "Whitespace removed.",
    ts_CommasReplacedWithDots: "Commas replaced with dots.",
    ts_MultipleMinusSignsReducedToOne: "Multiple minus signs reduced to one.",
    ts_MultipleDecimalDelimitersReducedToOne: "Multiple decimal delimiters reduced to one.",
    ts_NonNumberRemoved: "Non-number characters removed (except - and . ).",
    ts_NumberRounded: "The number was rounded.",
    ts_FailedRoundingTheNumber: "Failed rounding the number.",
    ts_MinusSignNotAllowed: "Minus sign is not allowed and has been removed.",
    ts_QuoteRemoved: "Quotes ('\"`) removed.",
    ts_Input: "Input",
    ts_IsInvalid: "is invalid",
    ts_showFiles: "Show files",
    ts_hideFiles: "Hide files",
    ts_deleteAllFiles: "Delete all files",
    sd_noFilesSelected: constVar["sd_noFilesSelected"],
    ts_filesSelected: "files to be uploaded",
    ts_onlyOneFileAllowed: "Only one file is allowed for this input",
    sd_From: constVar["sd_From"],
    sd_to: constVar["sd_to"],
    ts_correctGroups: "Multiple correct group inputs detected, pick one: ",
    ts_incorrectCharacter: "Incorrect characters detected: ",
    ts_inputTooLong: "Input contains too many characters. Maximum allowed:",
    ts_currently: "Currently: ",
    ts_inputTooShort: "Input contains too few characters. Minimum required:",
    ts_outOfRangeDate: "The date entered is out of range.",
    ts_outOfRangeTooEarly: "is too late when max allowed is",
    ts_outOfRangeTooLate: "is too early when min allowed is",
    ts_noValidDateFound: "The date entered is not valid.",
    ts_dateChangedTo: "is not a valid date and has been changed to",
    ts_dateChangedFrom: "Date entered has been changed from",
    ts_unsupportedDateFormat: "Unsupported date format",
    ts_parameter: "Parameter",
    sd_Month: constVar["sd_Month"],
    sd_Year: constVar["sd_Year"],
    sd_day: constVar["sd_day"],
    ts_pleaseChange: "Please change",
    ts_toMatchTheFormat: "to match the correct format",
    ts_whitespacesRemoved: "white spaces removed",
    ts_whitespaceRemoved: "whites pace removed",
    ts_wrongSeparators: "is wrong separators, correct format is",
    ts_dateFormatChangedTo: "Date format changed to",
    sd_of: constVar["sd_of"],
    sd_moreInfo: `${constVar["sd_moreIn"]} (${constVar["sd_in_aNewWindow"]})`,
    sd_orLess: constVar["sd_orLess"],
    sd_interoperable: constVar["sd_interoperable"],
    sd_registeredTitlePrefix: constVar["sd_registeredTitlePrefix"],
    sd_PermittedExtensions: constVar["sd_PermittedExtensions"],
    ts_leadtrailRemoved: "remove leading and trailing non-ascii & special chars",
    ts_emailmusthaveat: "An " + constVar["sd_Email_ad"] + " must have an \"\@\" !",
    ts_emailmusthavedomain: "An " + constVar["sd_Email_ad"] + " must end with \"domain.topLevelDomain\" e.g. \"ti.ch\" !",
    ts_wrongEmailSeparators: constVar["sd_emailEntryTooltip"] + " - " + constVar["sd_not"] + " \";\" (" + constVar["sd_semicolon"] + ") !",
    ts_emailmustonlyone: "Only one " + constVar["sd_Email_ad"] + " is allowed !",
};
