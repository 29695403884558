/**
 * @author gudiskis PrivaSphere confidential, (c) 2024 - 2024 all rights reserved
 */
import { translations } from "../languages/LangueFormat";
import { XMLParser } from "../XMLParser";
import { Utils } from "../Utils";
import { LoggerFactory } from "myd-commons";
import constVar from "../../../angular/src/assets/const.json";
export class Validator {
    constructor() {
        this.parser = new XMLParser();
        this.paddingSize = 1;
        this.errorContainerID = "error-container";
        this.errorMessageID = "error-message";
        this.errorMessageDetailsID = "error-details";
        this.notifContainerID = "notif-container";
        this.notifMessageID = "notif-message";
        this.notifMessageDetailsID = "notif-details";
        this.logger = LoggerFactory.getLogger(Validator.name);
    }
    createBrElement(element) {
        const brEl = document.createElement("br");
        element.appendChild(brEl);
    }
    inputFieldNotNull() {
        if (!this.inputField || !this.parentElementDom) {
            throw new Error(Utils.getCurrentTimeString() +
                " Error: The element or the parent element is null");
        }
    }
    setElementStyle() {
        let notif = document.createElement("div");
        notif.style.fontWeight = "bold";
        notif.style.fontSize = "15";
        notif.style.marginBottom = "10";
        notif.style.marginTop = "3";
        return notif;
    }
    addPaddingToParent(parent, id) {
        let count = this.getNumOfInfoBoxes(parent, id);
        parent.style.paddingBottom = count + "px";
    }
    numberOfMessages(parent, id, type) {
        let notifMessages = parent.querySelectorAll("#" + type + id);
        return notifMessages.length * this.paddingSize;
    }
    createPlusIcon(infoDetails, typeMessage) {
        const plusIcon = document.createElement("span");
        plusIcon.textContent = "+";
        plusIcon.style.cursor = "pointer";
        plusIcon.style.marginLeft = "5px";
        plusIcon.style.color = "black";
        plusIcon.style.fontSize = "20px";
        plusIcon.style.fontWeight = "bold";
        plusIcon.onclick = () => {
            if (infoDetails.style.display == "block") {
                plusIcon.textContent = "+";
                infoDetails.style.display = "none";
                let paddingSize = parseInt(this.parentElementDom.style.paddingBottom.replace('px', '')) -
                    this.numberOfMessages(infoDetails, this.inputField.id, typeMessage);
                if (paddingSize < this.paddingSize) {
                    paddingSize = this.paddingSize;
                }
                this.parentElementDom.style.paddingBottom = paddingSize + "px";
            }
            else {
                plusIcon.textContent = "-";
                infoDetails.style.display = "block";
                this.parentElementDom.style.paddingBottom =
                    parseInt(this.parentElementDom.style.paddingBottom.replace('px', '')) +
                        this.numberOfMessages(infoDetails, this.inputField.id, typeMessage) +
                        "px";
            }
        };
        return plusIcon;
    }
    showMainErrorInfo(infoMsg, errTitle, errCode) {
        this.showMainInfo(this.errorContainerID, "fieldError", constVar["ERROR_BOX_RED"], this.errorMessageID, infoMsg, this.errorMessageDetailsID, errTitle, errCode);
    }
    showErrorInfo(err, showMainInfo) {
        this.showInfo(constVar["ERROR_BOX_RED"], this.errorMessageID, err, this.errorMessageDetailsID, showMainInfo);
    }
    showNotifInfo(infoMsg, showMainInfo) {
        this.showInfo(constVar["ERROR_BOX_GREEN"], this.notifMessageID, infoMsg, this.notifMessageDetailsID, showMainInfo);
    }
    showMainNotifInfo(infoMsg) {
        this.showMainInfo(this.notifContainerID, null, constVar["ERROR_BOX_GREEN"], this.notifMessageID, infoMsg, this.notifMessageDetailsID);
    }
    showMainInfo(infoContainerID, cssClass, colorOfText, infoMsgID, infoMsg, infoDetailsID, errTitle, errCode) {
        if (this.inputField) {
            const infoContainer = document.createElement("div");
            infoContainer.id = infoContainerID + this.inputField.id;
            infoContainer.style.display = "table";
            if (cssClass) {
                this.inputField.classList.add(cssClass);
            }
            const infoMessage = this.setElementStyle();
            infoMessage.style.color = colorOfText;
            infoMessage.style.whiteSpace = "normal";
            infoMessage.id = infoMsgID + this.inputField.id;
            infoMessage.textContent = infoMsg;
            if (errTitle && errCode) {
                const acronym = document.createElement("acronym");
                acronym.title = errTitle;
                acronym.textContent = errCode;
                const small = document.createElement("small");
                small.innerHTML = " (";
                small.appendChild(acronym);
                small.innerHTML += ")";
                infoMessage.appendChild(small);
            }
            this.createBrElement(this.parentElementDom);
            infoContainer.appendChild(infoMessage);
            const infoDetails = document.createElement("div");
            infoDetails.style.display = "none";
            infoDetails.id = infoDetailsID + this.inputField.id;
            infoContainer.appendChild(infoMessage);
            infoMessage.appendChild(this.createPlusIcon(infoDetails, infoMsgID));
            infoContainer.appendChild(infoDetails);
            this.parentElementDom.appendChild(infoContainer);
            this.addPaddingToParent(this.parentElementDom, this.inputField.id);
            this.parentElementDom.style.display = "block";
        }
    }
    showInfo(color, messageID, info, infoMessageDetailsID, showMainInfo) {
        var _a, _b;
        if (this.inputField) {
            let infoContainer = (_a = this.parentElementDom) === null || _a === void 0 ? void 0 : _a.querySelector("#" + infoMessageDetailsID + this.inputField.id);
            if (!infoContainer) {
                showMainInfo();
                infoContainer = (_b = this.parentElementDom) === null || _b === void 0 ? void 0 : _b.querySelector("#" + infoMessageDetailsID + this.inputField.id);
            }
            const infoMessage = this.setElementStyle();
            infoMessage.style.color = color;
            infoMessage.style.whiteSpace = 'pre-wrap';
            infoMessage.id = messageID + this.inputField.id;
            infoMessage.textContent = info;
            infoContainer === null || infoContainer === void 0 ? void 0 : infoContainer.appendChild(infoMessage);
            this.createBrElement(infoContainer);
        }
    }
    numberOfBrElements(parent) {
        let brElements = parent.querySelectorAll("br");
        return brElements.length;
    }
    shortenString(str) {
        if (str.length > 6) {
            return str.substring(0, 6) + '...';
        }
        else {
            return str;
        }
    }
    removeInfoMessage(cssClass, infoContainerID) {
        if (this.inputField) {
            if (cssClass) {
                this.inputField.classList.remove(cssClass);
                this.removeClassIfEmpty();
            }
            const parentElement = this.parentElementDom;
            const infoMessage = this.parentElementDom.querySelector("#" + infoContainerID + this.inputField.id);
            if (infoMessage) {
                parentElement.removeChild(infoMessage);
            }
            let brElement = parentElement.querySelector("br");
            if (brElement) {
                parentElement.removeChild(brElement);
            }
            this.parentElementDom.style.paddingBottom = (this.getNumOfInfoBoxes(this.parentElementDom, this.inputField.id) +
                this.getNumberOfChildren() * this.paddingSize) + "px";
        }
    }
    removeErrorMessage() {
        this.removeInfoMessage("fieldError", this.errorContainerID);
    }
    removeClassIfEmpty() {
        if (this.inputField.classList.length === 0) {
            this.inputField.removeAttribute("class");
        }
    }
    getNumberOfChildren() {
        let parent = this.parentElementDom;
        let count = 0;
        if (parent) {
            let errEl = parent.querySelector("#" + this.errorMessageDetailsID + this.inputField.id);
            if (errEl && errEl.style.display === "block") {
                count += parent.querySelectorAll("#" + this.errorMessageDetailsID + this.inputField.id).length;
            }
            let notifEl = parent.querySelector("#" + this.notifMessageDetailsID + this.inputField.id);
            if (notifEl && notifEl.style.display === "block") {
                count += parent.querySelectorAll("#" + this.notifMessageDetailsID + this.inputField.id).length;
            }
        }
        return count;
    }
    getNumOfInfoBoxes(parent, id) {
        let notifMessages = parent.querySelectorAll("#" + this.notifContainerID + id + ", #" + this.errorContainerID + id);
        let count = notifMessages.length;
        return count * this.paddingSize + this.numberOfBrElements(parent) * 5;
    }
    removeNotifMessage() {
        this.removeInfoMessage(null, this.notifContainerID);
    }
    notifMessage(inputField, pattern, replace, notifMsg) {
        const original = inputField.value;
        const prevLgth = original.length;
        let changed = original.replace(pattern, replace);
        this.notifMessageChanged(changed, original, prevLgth, notifMsg, inputField, "removed");
    }
    notifMessageChanged(changed, original, prevLgth, notifMsg, inputField, action) {
        if (changed !== original) {
            const newLgth = changed.length;
            const removed = prevLgth - newLgth;
            const xlatEnHash = translations["en"];
            let xlatEn = "***unknownTranslation english for *** notifMsg-key '" + notifMsg + "'";
            if (null == xlatEnHash) {
                this.logger.error("null == xlatEnHash");
            }
            else {
                const xlatEnCand = xlatEnHash[notifMsg];
                if (null != xlatEnCand && '' != xlatEnCand.trim()) {
                    xlatEn = xlatEnCand;
                }
            }
            let xlatCurr = "***unknownTranslation " + currentLanguageTS
                + " for *** " + notifMsg;
            const xlatCurrLangHash = translations[currentLanguageTS];
            if (null == xlatCurrLangHash) {
                this.logger.error("null == xlatCurrLangHash");
            }
            else {
                const xlatCurrCand = xlatCurrLangHash[notifMsg];
                if (null != xlatCurrCand && '' != xlatCurrCand.trim()) {
                    xlatCurr = xlatCurrCand;
                }
            }
            this.logger.info('Input "' + original + '" has been changed to "' +
                changed + '". ' + removed +
                " chars have been " + action + ". " + xlatEn);
            inputField.value = changed;
            this.showNotif(xlatCurr + " "
                + xlatCurrLangHash["sd_From"] + " \"" + original + "\" " +
                xlatCurrLangHash["sd_to"] + " \"" + changed + "\"");
        }
    }
}
