/**
 * @author gudiskis PrivaSphere confidential, (c) 2023 - 2024 all rights reserved
 */
import constVar from "../../../angular/src/assets/i18n/de.json";
export var deTranslation = {
    ts_Delete: "Löschen",
    ts_CheckAttaBeforeDispatch: "Anhang-Kontrolle vor Versand",
    ts_TooBig: "Zu gross",
    ts_attachment: "Anhang",
    ts_available: "verfügbar",
    ts_cumulated: "kumuliert",
    ts_fileNameExtension: "Dateinamen-Endung",
    ts_instead: "anstatt",
    ts_moreInfo: "Mehr Info",
    ts_in_aNewWindow: "in einem neuen Browser-Fenster",
    ts_MoreThanOneNumberGroupPresent: "Mehr als eine Zahlengruppe ist vorhanden.",
    ts_NoNumberPresent: "Keine Zahl ist vorhanden.",
    ts_TooManyDecimalDelimiters: "Zu viele Dezimaltrennzeichen.",
    ts_TooManyMinusSigns: "Zu viele Minuszeichen.",
    ts_LeadingZerosRemoved: "Führende Nullen wurden entfernt.",
    ts_WhitespaceRemoved: "Leerzeichen entfernt.",
    ts_CommasReplacedWithDots: "Kommas durch Punkte ersetzt.",
    ts_MultipleMinusSignsReducedToOne: "Mehrere Minuszeichen auf eins reduziert.",
    ts_MultipleDecimalDelimitersReducedToOne: "Mehrere Dezimaltrennzeichen auf eins reduziert.",
    ts_NonNumberRemoved: "Nicht-Zahlenzeichen entfernt (außer - und . ).",
    ts_NumberRounded: "Die Zahl wurde gerundet.",
    ts_FailedRoundingTheNumber: "Fehler beim Runden der Zahl.",
    ts_MinusSignNotAllowed: "Minuszeichen ist nicht erlaubt und wurde entfernt.",
    ts_QuoteRemoved: "Anführungszeichen ('\") entfernt.",
    ts_Input: "Eingabe",
    ts_IsInvalid: "ist ungültig",
    ts_showFiles: "Dateien anzeigen",
    ts_hideFiles: "Dateien ausblenden",
    ts_deleteAllFiles: "Alle Dateien löschen",
    sd_noFilesSelected: constVar["sd_noFilesSelected"],
    ts_filesSelected: "Dateien zum Hochladen ausgewählt",
    ts_onlyOneFileAllowed: "Für diese Eingabe ist nur eine Datei erlaubt",
    sd_From: constVar["sd_From"],
    sd_to: constVar["sd_to"],
    ts_correctGroups: "Mehrere korrekte Gruppeneingaben erkannt, wählen Sie eine aus: ",
    ts_incorrectCharacter: "Falsches Zeichen erkannt: ",
    ts_inputTooLong: "Die Eingabe enthält zu viele Zeichen. Maximal erlaubt:",
    ts_currently: "Aktuell: ",
    ts_inputTooShort: "Die Eingabe enthält zu wenige Zeichen. Mindestens erforderlich:",
    ts_outOfRangeDate: "Das eingegebene Datum liegt außerhalb des zulässigen Bereichs.",
    ts_outOfRangeTooEarly: "ist zu spät, wenn maximal erlaubt ist",
    ts_outOfRangeTooLate: "ist zu früh, wenn minimal erlaubt ist",
    ts_noValidDateFound: "Das eingegebene Datum ist ungültig.",
    ts_dateChangedTo: "ist kein gültiges Datum und wurde geändert zu",
    ts_dateChangedFrom: "Das eingegebene Datum wurde geändert von",
    ts_unsupportedDateFormat: "Nicht unterstütztes Datumsformat",
    ts_parameter: "Parameter",
    sd_Month: constVar["sd_Month"],
    sd_Year: constVar["sd_Year"],
    sd_day: constVar["sd_day"],
    ts_pleaseChange: "Bitte ändern",
    ts_toMatchTheFormat: "um das richtige Format zu entsprechen",
    ts_whitespacesRemoved: "leerzeichen entfernt",
    ts_whitespaceRemoved: "leerzeichen entfernt",
    ts_wrongSeparators: "ist falsche Trennzeichen, korrektes Format ist",
    ts_dateFormatChangedTo: "Datumsformat geändert zu",
    sd_of: constVar["sd_of"],
    sd_moreInfo: `${constVar["sd_moreIn"]} (${constVar["sd_in_aNewWindow"]})`,
    sd_orLess: constVar["sd_orLess"],
    sd_interoperable: constVar["sd_interoperable"],
    sd_registeredTitlePrefix: constVar["sd_registeredTitlePrefix"],
    sd_PermittedExtensions: constVar["sd_PermittedExtensions"],
    ts_leadtrailRemoved: "Entferne führende und nachfolgende Nicht-ASCII- und Sonderzeichen",
    ts_emailmusthaveat: "Ein " + constVar["sd_Email_ad"] + " muss ein \"\@\" haben!",
    ts_emailmusthavedomain: "Ein " + constVar["sd_Email_ad"] + " muss mit \"domain.topLevelDomain\" enden, z. B. \"ti.ch\" !",
    ts_wrongEmailSeparators: constVar["sd_emailEntryTooltip"] + " - " + constVar["sd_not"] + " \";\" (" + constVar["sd_semicolon"] + ") !",
    ts_emailmustonlyone: "Nur ein " + constVar["sd_Email_ad"] + " ist erlaubt !",
};
